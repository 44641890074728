.Testimonials {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    width: 100%;
    /* height: 100vh; */
    padding: 4rem 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Testimonials:before{
    content: '';
    background: url('./imgs/ArmyBombNew.jpg') no-repeat center center/cover;
    background-attachment: fixed;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.Testimonials .container {
    max-width: 1000px;
    /* display: flex; */
    text-align: center;
    max-width: 100vw;
    width: 100%;
    padding-bottom: 2rem;
    /* overflow: initial; */
}

.title{
    margin-top: 7px;
}

.title-2{
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 14px;
    
}

.year{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    
}

.testimonial:active{
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}


.slick-dots li button:before{
    color: rgba(255, 255, 255, 0.696);
    opacity: 1;
    font-size: 12px;
}

.slick-dots li.slick-active button:before{
    color:#9c31e4;
    opacity: 1;
}

.slick-dots{
    bottom: -50px;
}

.Testimonials .container img {
    margin: auto;
    width: 100px;
    border: 7px solid rgba(255, 255, 255, 0.15);
    border-radius: 50%; 
}

@media screen and (max-width: 1024px) {
    .Testimonials:before {
      background-attachment: scroll;
    }
  }