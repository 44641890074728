.projects{
    width: 100%;
}

.projects .container{
    margin: auto;
    max-width: 1140px;
    text-align: center;
    padding: 3rem 1rem;
}

.projects .container h2{
    font-size: 32px;
    margin-bottom: 30px;
}

.projects .container h2 .purple{
    color: #9c31e4;

}



.more{
    color: #aaaaaa;
    font-size: 15px;
}

/* .b{
    margin-bottom: 0;
} */
.row{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1240px;
    margin: 1.2rem auto;
    /* outline: 2px solid black; */
    gap: 30px;
    text-align: left;
    /* justify-content: space-around; */
}



.desc{
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    margin: 20px 0;
    color: #6c665c;
}



/* .col {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
} */

.row .box{
    transition: all ease-in-out 0.3s;
    max-width: 400px;
    /* background-color: aliceblue; */
    padding: 50px 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.lightpurple{
    display: block;
    font-size: 28px;
    font-weight: 700;
    color: #bf88ff;
    /* margin-bottom: 56px; */
}

.projects .container .row .box:hover{
    background-color: #9c31e4;
    transform: translateY(-5px);
    color: c;
}

.projects .box:hover span, .projects .box:hover h4, .projects .box:hover p{
    color: white;
}



@media screen and (max-width:940px){
    .row{
        grid-template-columns: 1fr;
        width: 90%;
    }

    .row .box{
        max-width: 100%;
    }
}