.footer{
    background: #2f2d35;
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 30px 0;
}

.footer .container{
    max-width: 1140px;
    /* position: sticky;
    bottom: 0; */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    margin: auto;
}

.footer h3{
    font-size: 36px;
    font-weight: 700;
    color: #9c31e4;
    position: relative;
    padding: 0;
    /* display: block;
    margin: auto; */
    margin-bottom: 15px;
}

.footer p{
        /* font-size: 15px; */
        font-style: italic;
        padding: 0;
        margin: 0 0 30px 0;
}

.footer .my-links{
    margin-bottom: 20px;
}

.footer .icon-bg{
    font-size: 18px;
    display: inline-block;
    background: #3e3b46;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

.footer .icon-bg:hover  {
    background-color: #9c31e4;
}



.footer a{
    text-decoration: none;
    color: #fff;
}


.footer a:visited{
    text-decoration: none;
    color: #afd8ee;
}

.footer a:hover{
    color: #9c31e4;
}

.footer nav ul{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

/* .footer nav li{
list-style: none;
   margin: 2px;
} */