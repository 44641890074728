.contact{
    background-color: #fff;
    width: 100%;    
}

.contact .container {
    max-width: 1140px;
    margin: auto;
    padding: 15px;
}

.contact .container h2 {
    font-size: 32px;
    margin: 15px auto;
    text-align: center;
    margin-bottom: 30px;    
}

.contact .box{
    margin-top: 2rem;

}

.contact .contain{
    box-shadow: 0px 2px 15px rgb(0 0 0 / 0.10);
}

.contact .form{
    box-shadow: 0 0 24px 0 rgb(0 0 0 / 0.12);
    background-color: #fff;
}



.contact .contain{
    display: flex;
    flex-direction: row;
    padding: 30px 30px;
}

.contact .email{
    width: 50%;
    display: flex;
}
.contact .linkedin{
    width: 50%;
    display: flex;
}

.contact a i{
    font-size: 20px;
    color: #9c31e4;
    float: left;
    width: 44px;
    height: 44px;
    background: #e8d0ff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .details-div{
    margin-left: 15px;
}

.details-div h4{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #433f39;
}

.details-div p{
    /* margin-bottom: 0; */
    font-size: 14px;
    color: #7a7368;
    font-weight: 400;
}

.email-icon{
    /* color: transparent; */
    fill: #9c31e4;
    color: #e8d0ff;
}

.icons{
    transition: all 0.3s ease-in-out;
}

.icons:hover{
    background-color: #9c31e4;
}

.icons:hover .email-icon{
    fill: #fff;
    color: #9c31e4;
}

.icons:hover .icon{
    fill: #fff;
    /* color: #fff; */
} 

.form{
    padding: 30px;
    margin-bottom: 1rem;
}

.row-c{
    display: flex;
    width: 100%;
    gap: 10px;
}


.row1 div{
    width: 50%;
}

input{
    width: 100%;
    height: 44px;
    font-size: 14px;
    border: 1px solid #ced4da;
    padding: .375rem .75rem;
    font-weight: 400;
    background-color: #fff;
    color: #495057;
    
}

.row-c div{
    margin-bottom: 1.3rem;
}

.row2 div{
    width: 100%;
}

.row3{
    width: 100%;
    gap: 0px;
}

.row-c textarea{
    display: block;
    padding: 10px 12px;
    height: auto;   
    font-size: 14px;
    font-weight: 400;
    color: #495057;
    border: 1px solid #ced4da;
    font-family: 'poppins',sans-serif;
    width: 100%;
    resize: vertical;
}

.row-c .message{
    width: 100%;
}

.contact button{
    background: #9c31e4;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
    margin: auto;
}
.message-button{
    display: block;
    margin: auto;
    font-size: 16px;
    font-weight: 500;
    transition: all 1s ease-in-out;
}

.message-button:hover{
    background-color: #18d26e;
}

.contact button:active{
    background-color: #18d26e;
}

.contact button:hover, .contact button:active{
    cursor: pointer;
}

@media screen and (max-width: 720px){
    .contact .row-c{
        flex-direction: column;
    }

    .row-c input, .row-c div{
        width: 100%;
    }

    .contact .contain{
        flex-direction: column;

    }

    .contact .email{
        margin-bottom: 30px;
    }
}